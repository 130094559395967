import React from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

import { fullVwVh, muliFont, flexCenter, borderBox, column } from "mixins";
import { draw, progressCircular, progressCircularPseudo } from "./keyframes";

const Layout = styled.section`
  ${fullVwVh};
  ${column};
  ${flexCenter};
  ${borderBox};
  background: rgba(0, 0, 0, 0.05);
  z-index: 1002;
  @media (max-width: 540px) {
    padding: 20px;
  }
`;

const Circle = styled.div`
  ${column};
  ${flexCenter};
  width: 124px;
  height: 124px;
  background: #ffffff;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  svg {
    path {
      stroke: #6280da;
    }
  }
  .platform {
    position: absolute;
    top: 41px;
  }
`;

const Heading = styled.h3`
  ${muliFont};
  margin: 30px 0 20px;
  color: #181818;
  font-weight: bold;
  text-align: center;
  @media (max-width: 540px) {
    font-size: 16px;
  }
`;

const Text = styled.div`
  ${muliFont};
  text-align: center;
  color: #333333;
  @media (max-width: 540px) {
    font-size: 12px;
  }
`;

const Progress = styled.progress`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  color: ${({ color }) => color};
  box-sizing: border-box;
  border: none;
  border-radius: 50%;
  width: ${({ size = 40 }) => `${size}px`};
  height: ${({ size = 40 }) => `${size}px`};
  background-color: transparent;
  font-size: ${({ size = 40 }) => `${size / 3}px`};
  overflow: hidden;
  &::-webkit-progress-bar {
    background-color: transparent;
  }
  &:indeterminate {
    -webkit-mask-image: linear-gradient(transparent 50%, black 50%), linear-gradient(to right, transparent 50%, black 50%);
    mask-image: linear-gradient(transparent 50%, black 50%), linear-gradient(to right, transparent 50%, black 50%);
    animation: ${progressCircular} 6s infinite cubic-bezier(0.3, 0.6, 1, 1);
    &::before,
    &::-webkit-progress-value {
      content: "";
      display: block;
      box-sizing: border-box;
      margin-bottom: 0.25em;
      border: solid 0.25em transparent;
      border-top-color: currentColor;
      border-radius: 50%;
      width: 100% !important;
      height: 100%;
      background-color: transparent;
      animation: ${progressCircularPseudo} 0.75s infinite linear alternate;
    }
    &::-moz-progress-bar {
      box-sizing: border-box;
      border: solid 0.25em transparent;
      border-top-color: currentColor;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      background-color: transparent;
      animation: ${progressCircularPseudo} 0.75s infinite linear alternate;
    }
    &::-ms-fill {
      animation-name: -ms-ring;
    }
  }
`;

const ProgressBar = styled(Progress)`
  position: absolute;
  z-index: 1;
  top: 33px;
  &:indeterminate {
    animation-duration: 12s;
    &::before,
    &::-webkit-progress-value {
      animation-duration: 1.5s;
    }
    &::-moz-progress-bar {
      animation-duration: 1.5s;
    }
  }
`;

const Card = styled.div`
  ${column};
  ${flexCenter};
  width: 440px;
  background: #ffffff;
  border: 1px solid #f9f9f9;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 35px 25px 55px;
  margin: auto;
  position: relative;
  @media (max-width: 540px) {
    width: 100%;
  }
`;

const LineBlock = styled.div`
  right: 40px;
  top: 41px;
  width: 44px;
  height: 30px;
  position: absolute;
  background: #fff;
  animation: ${draw} 1.5s linear infinite;
`;

const BaseLayout = ({ children, heading, text }) => {
  return (
    <Layout>
      <Card>
        <ProgressBar size={128} color="#6280DA" />
        <Circle>{children}</Circle>
        <Heading fontSize={20}>{heading}</Heading>
        <Text lineHeight={160}>{text}</Text>
      </Card>
    </Layout>
  );
};

export const CreatePlatformLoader = () => {
  return createPortal(
    <BaseLayout heading={`Creating  Platform...`} text="Platform is being created. It will be ready in a few seconds.">
      <svg width="72" height="64" viewBox="0 0 72 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.91218 1H66.4591V51.6251L71 58.2956L66.4591 63H40.4012H31.3194H4.91218L1 58.2956L4.91218 51.6251V1Z" fill="white" />
        <path
          d="M66.4591 51.6251V1H4.91218V51.6251M66.4591 51.6251H4.91218M66.4591 51.6251L71 58.2956M4.91218 51.6251L1 58.2956M1 58.2956L4.91218 63H31.3194M1 58.2956H27.3373M71 58.2956L66.4591 63H40.4012M71 58.2956H44.5928M31.3194 63L27.3373 58.2956M31.3194 63H40.4012M27.3373 58.2956H44.5928M40.4012 63L44.5928 58.2956M9.66267 41.0226V47.4122H62.2675V5.70442H9.66267V37.231"
          stroke="#6280DA"
          stroke-width="2"
          stroke-linejoin="round"
        />
      </svg>

      {/* line  */}
      <svg className="platform" width="44" height="30" viewBox="0 0 44 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 28.5946L17.8363 9.84711L26.2196 18.5538L42.7066 1" stroke="#6280DA" stroke-width="2" stroke-linejoin="round" />
      </svg>
      <LineBlock />
    </BaseLayout>,
    document.getElementById("globalModal500Error"),
  );
};
