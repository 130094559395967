import React from "react";

import * as Preview from "./styled";
import DarkBg from "assets/sign-in-dark.png";
import { defaultLogo } from "helpers";
import inputs from "assets/inputs-preview.svg";

export const AuthPreviev = ({ data: { platformLogo, slogan, description, footerText, companyName } }) => {
  return (
    <Preview.Wrapper>
      <Preview.PreviewLeft bg={DarkBg}>
        <Preview.Logo src={platformLogo || defaultLogo} />
        <Preview.Text fWeight={600}>{slogan || "Platform Slogan goes here"}</Preview.Text>
        <Preview.Text o={0.7} lHeight="160" size={16}>
          {description ||
            "Platform description goes her with a max of 150 characters to better describe your company activity"}
        </Preview.Text>
        <Preview.BottomBlock>
          <Preview.Text size={16} fWeight="bold" lHeight={160} o={0.8} m="0 0 14px">
            {companyName || "Company Name"} {new Date().getFullYear()}. Powered by VALK
          </Preview.Text>
          <Preview.Text size={14} lHeight={160} o={0.6} m="0 0 44px">
            {footerText || "Disclaimer, copyright and footer text to inform your client where you are regulated"}
          </Preview.Text>
          <Preview.Row>
            <Preview.Text size={14} lHeight={160} m="0 24px 0 0">
              Privacy & Cookies
            </Preview.Text>
            <Preview.Text size={14} lHeight={160} m="0">
              Terms & Conditions
            </Preview.Text>
          </Preview.Row>
        </Preview.BottomBlock>
      </Preview.PreviewLeft>
      <Preview.FormBlock>
        <Preview.Center>
          <Preview.Heading>Sign In</Preview.Heading>
          <img src={inputs} alt="preview" />
        </Preview.Center>
      </Preview.FormBlock>
    </Preview.Wrapper>
  );
};
