import React, { useRef, useEffect } from "react";

import { Card, CardName, Row, Col, BlueBg, Text, Btn, Label } from "components";
import { TextFieldComponent, TextAreaComponent } from "components/forms";
import { CropperImg } from "components/cropper";

const marginSize = "0 0 24px";

export const PlatformDesign = ({
  onChange = console.log,
  onPreview,
  data: { slogan, description, footerText, platformLogo },
}) => {
  const base64Ref = useRef(null);
  const createAreaProps = (areaHeight = 84) => ({ isInvestor: true, fSize: 12, areaHeight });

  const _onPreview = () => {
    onChange("platformLogo", base64Ref.current.getCropData())();
    onPreview();
  };

  useEffect(() => {
    const onMove = () => {
      const img = base64Ref.current.getCropData();
      if (img && img !== platformLogo) {
        onChange("platformLogo", img)();
      }
    };
    window.addEventListener("mousemove", onMove);

    return () => window.removeEventListener("mousemove", onMove);
  }, [platformLogo, onChange]);

  return (
    <Card>
      <CardName>
        Platform Design <span>Optional</span>
      </CardName>
      <Text m="-8px 0 24px">You can make all these design settings later on Settings page.</Text>
      <Row m={marginSize}>
        <TextFieldComponent
          isInvestor
          label="PLATFORM slogan (for sign in & sign up pages)"
          placeholder="Platform slogan goes here"
          value={slogan}
          onChange={(e) => onChange("slogan", e.target.value)()}
        />
      </Row>
      <Row m={marginSize}>
        <TextAreaComponent
          {...createAreaProps()}
          label="Platform description (150 Characters MAX)"
          placeholder="Platform description goes here"
          defaultValue={description}
          onChange={(e) => onChange("description", e.target.value)()}
          maxLength={150}
        />
      </Row>
      <Row m={marginSize}>
        <TextAreaComponent
          {...createAreaProps(68)}
          label="footer TEXT (150 characters max)"
          placeholder="Disclaimer and company regulation"
          defaultValue={footerText}
          onChange={(e) => onChange("footerText", e.target.value)()}
          maxLength={150}
        />
      </Row>
      <Row>
        <Col w="calc(100% - 203px)">
          <Label>Logo Image (Will be resized to 235x70)</Label>
          <CropperImg
            ref={base64Ref}
            onMouseLeave={onChange(
              "platformLogo",
              base64Ref && base64Ref.current ? base64Ref.current.getCropData() : null
            )}
            defaultImageDate={platformLogo}
          />
          <Text small>Please use a logo for dark backgrounds</Text>
        </Col>
        <Col w="173px">
          <Label>PREVIEW</Label>
          <BlueBg>
            <Btn transparent width={125} margin="auto" onClick={_onPreview}>
              PREVIEW
            </Btn>
          </BlueBg>
          <Text small>Welcome Page Preview</Text>
        </Col>
      </Row>
    </Card>
  );
};
