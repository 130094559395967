import styled, { css } from "styled-components";

import logo from "assets/VALK.png";
import { shadeColor } from "helpers";
import { ReactComponent as CheckIcon } from "assets/check-icon.svg";
import { ReactComponent as XIcon } from "assets/x-icon.svg";

const center = css`
  align-items: center;
`;

const card = css`
  background: #ffffff;
  border-radius: 4px;
`;

const dots = css`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const iconButton = css`
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #333333;
  padding: 0 15px;
  > svg,
  img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
`;

export const Wrapper = styled.section`
  display: flex;
  box-sizing: border-box;
  padding: 65px 0;
  width: 100vw;
  min-height: 100vh;
  background: #f7f9fb;
`;

export const Flex = styled.div`
  display: flex;
  box-sizing: border-box;
`;

export const Col = styled(Flex)`
  width: ${({ w = "auto" }) => w};
  flex-direction: column;
  > .side-buttons {
    display: flex;
  }
  @media (max-width: 1280px) {
    > .side-buttons {
      display: none;
    }
  }
`;

export const MainCol = styled(Col)`
  ${center};
  width: 676px;
`;

export const SideCol = styled(Col)`
  width: calc(50% - 338px);
  ${({ left }) => (left ? `padding-right: 30px;align-items: flex-end;` : `padding-left: 30px;`)};
`;

export const UserName = styled(Flex)`
  ${card};
  ${center};
  ${iconButton};
  width: 205px;
  height: 36px;
  > span {
    width: 160px;
    ${dots};
  }
`;

const LogoBlock = styled(Col)`
  width: 241px;
  margin: auto;
  > img {
    width: 166px;
    height: 40px;
    display: block;
    margin: 0 auto 24px;
  }
`;

const PageDesc = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 145%;
  text-align: center;
  letter-spacing: -0.02em;
  color: rgba(51, 51, 51, 0.9);
`;

export const Card = styled(Col)`
  ${card};
  width: 100%;
  padding: 25px 30px 30px;
  margin-bottom: ${({ noMargin }) => (noMargin ? 0 : "25px")};
`;

export const Row = styled(Flex)`
  width: ${({ w = "100%" }) => w};
  margin: ${({ m = 0 }) => m};
  justify-content: ${({ j = "space-between" }) => j};
  align-items: ${({ a = "center" }) => a};
  position: relative;
  > .side-buttons {
    display: none;
  }
  @media (max-width: 1280px) {
    > .side-buttons {
      display: flex;
    }
  }
`;

export const Btn = styled.button`
  position: relative;
  white-space: nowrap;
  padding-bottom: 0;
  padding-top: 0;
  width: ${({ width, fullWidth }) => (width ? `${width}px` : fullWidth ? "100%" : "94px")};
  border: 1px solid ${({ hide }) => (hide ? "#CACACA" : "#6280DA")};
  height: 32px;
  background: ${({ hide, transparent }) => (hide ? "#fff" : transparent ? "transparent" : "#6280DA")};
  color: ${({ hide, transparent }) => (transparent ? "#6280DA" : hide ? "#CACACA" : "#fff")};
  font-size: 10px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  border-radius: 4px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: ${({ margin }) => (margin ? margin : "0")};
  transition: all 0.1s linear;
  &:disabled {
    background: #e3e3e3 !important;
    border: 1px solid #e3e3e3;
    color: white !important;
    &::before {
      background: #e3e3e3 !important;
    }
  }
  //states
  &:hover {
    &::before {
      display: ${({ hideBorder = false }) => (hideBorder ? "none" : "block")};
    }
  }
  &:active {
    ${({ hide }) => {
      const activeColor = hide ? shadeColor("#CACACA", -15) : shadeColor("#6280DA", -15);
      return `background: ${activeColor};border: 1px solid ${activeColor};color:#fff;`;
    }};
    &::before {
      display: none;
    }
  }
  &::before {
    transition: all 0.3s linear;
    content: "";
    position: absolute;
    display: none;
    bottom: -1px;
    left: -1px;
    height: 3px;
    background: ${({ hide }) => (hide ? "#CACACA" : "#6280DA")};
    width: calc(100% + 2px);
    filter: brightness(0.85);
    border-radius: 0 0 4px 4px;
  }
  > svg {
    margin-right: 6px;
  }
`;

export const CardName = styled.h3`
  font-size: ${({ fontSize = "14px" }) => fontSize};
  line-height: 135%;
  color: #181818;
  margin: ${({ m = "0 0 25px" }) => m};
  font-weight: 700;
  > span {
    color: rgba(24, 24, 24, 0.4);
    margin-left: 6px;
  }
`;

export const Label = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 8px;
  color: ${({ error = false }) => (error ? "#D96666" : "rgba(24, 24, 24, 0.6)")};
`;

export const PassInfo = styled.div`
  background: #f2f4f7;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  padding: ${({ p = "20px 18px" }) => p};
  display: flex;
  flex-direction: column;
  margin-top: ${({ mt = "25px" }) => mt};
`;

const ProgressLine = styled.div`
  height: ${({ slim = false }) => (slim ? "12px" : "14px")};
  width: ${({ width }) => `${width}%`};
  background-color: ${({ width, listError }) => (listError ? "#D96666" : width < 75 ? "#FAD978" : "#55B17C")};
  border-radius: 2px;
  transition: 0.5s linear all;
`;

const ProgressBg = styled.div`
  width: calc(100% - 150px);
  height: ${({ slim = false }) => (slim ? "12px" : "14px")};
  background: #e3e6e9;
  border-radius: 2px;
  display: flex;
  overflow: hidden;
`;

const StepLine = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: ${({ mb = "14px" }) => mb};
  &:last-child {
    margin-bottom: 0;
  }
  > svg {
    margin-right: 10px;
    width: 12px;
    height: 12px;
    path {
      stroke: ${({ error, done }) => (error ? "#D96666" : done ? "#55B17C" : "#AEB1B4")};
    }
  }
`;

export const StepText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: ${({ size = 14 }) => `${size}px`};
  line-height: 135%;
  letter-spacing: -0.02em;
  color: ${({ error, empty }) => (error ? "#D96666" : empty ? "#AEB1B4" : "#181818")};
`;

export const BlueBg = styled(Flex)`
  ${card};
  background: #f3f6ff;
  width: 100%;
  height: 82px;
  margin-bottom: 10px;
`;

export const Text = styled.p`
  margin: ${({ m = 0 }) => m};
  font-size: ${({ small }) => (small ? "12px" : "14px")};
  line-height: 135%;
  letter-spacing: -0.02em;
  color: ${({ small }) => (small ? "rgba(24, 24, 24, 0.4)" : "#333333")};
  text-align: ${({ align = "left" }) => align};
`;

export const DomainPreview = styled(Flex)`
  ${card};
  ${center};
  ${iconButton};
  height: 42px;
  background: #f1f3f8;
  width: 100%;
  > span {
    width: calc(100% - 56px);
    ${dots};
    .valk-domain {
      opacity: 0.5;
    }
  }
`;

export const Info = styled.div`
  width: 100%;
  height: 42px;
  background: #f6f8fb;
  padding: 0 20px;
  display: flex;
  align-items: center;
  margin-top: 18px;
  box-sizing: border-box;
  > svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  > div {
    font-size: 12px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: #333333;
  }
`;

export const PageLogo = ({ description }) => (
  <LogoBlock>
    <img alt="logo" src={logo} />
    <PageDesc>{description}</PageDesc>
  </LogoBlock>
);

export const Progress = ({ password = "", arr = [], slim }) => {
  const combinationsError = arr.map((str) => password.includes(str)).some((e) => e);

  const part1 = /(?=.*[A-Z])/.test(password) && password.length > 4 ? 25 : 0;
  const part2 = /(?=.*[^0-9A-Za-z])/.test(password) && password.length > 5 ? 25 : 0;
  const part3 = password && !combinationsError && password.length > 6 ? 25 : 0;
  const part4 = /(?=.*[0-9])/.test(password) && password.length > 7 ? 25 : 0;
  const progress = part1 + part2 + part3 + part4;
  return (
    <ProgressBg slim={slim}>
      <ProgressLine slim={slim} width={progress} listError={!part1 || !part2 || !part3 || !part4} />
    </ProgressBg>
  );
};

export const Step = ({ description, regExp, value = "", isList = false, arr = [], size, mb }) => {
  const done = isList ? value && arr.map((str) => !value.includes(str)).some((e) => e) : value && regExp.test(value);
  const error = isList ? value && arr.map((str) => value.includes(str)).some((e) => e) : value && !regExp.test(value);
  const empty = !done && !error;
  return (
    <StepLine done={done} error={error} mb={mb}>
      {error ? <XIcon /> : <CheckIcon />}
      <StepText size={size} done={done} error={error} empty={empty}>
        {description}
      </StepText>
    </StepLine>
  );
};
