export const UserFill = ({ color = "#6280DA" }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 15.75V14.25C15 13.4544 14.6839 12.6913 14.1213 12.1287C13.5587 11.5661 12.7956 11.25 12 11.25H6C5.20435 11.25 4.44129 11.5661 3.87868 12.1287C3.31607 12.6913 3 13.4544 3 14.25V15.75"
      fill={color}
    />
    <path
      d="M9 8.25C10.6569 8.25 12 6.90685 12 5.25C12 3.59315 10.6569 2.25 9 2.25C7.34315 2.25 6 3.59315 6 5.25C6 6.90685 7.34315 8.25 9 8.25Z"
      fill={color}
    />
  </svg>
);

export const EyePassword = ({ color = "#6280DA", style, onClick = console.log }) => (
  <svg
    style={style}
    onClick={onClick}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.583008 7.00004C0.583008 7.00004 2.91634 2.33337 6.99967 2.33337C11.083 2.33337 13.4163 7.00004 13.4163 7.00004C13.4163 7.00004 11.083 11.6667 6.99967 11.6667C2.91634 11.6667 0.583008 7.00004 0.583008 7.00004Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 8.75C7.9665 8.75 8.75 7.9665 8.75 7C8.75 6.0335 7.9665 5.25 7 5.25C6.0335 5.25 5.25 6.0335 5.25 7C5.25 7.9665 6.0335 8.75 7 8.75Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Globe = ({ color = "#6280DA" }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.00016 14.6666C11.6821 14.6666 14.6668 11.6819 14.6668 7.99998C14.6668 4.31808 11.6821 1.33331 8.00016 1.33331C4.31826 1.33331 1.3335 4.31808 1.3335 7.99998C1.3335 11.6819 4.31826 14.6666 8.00016 14.6666Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1.3335 8H14.6668" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M8.00016 1.33331C9.66768 3.15888 10.6153 5.528 10.6668 7.99998C10.6153 10.472 9.66768 12.8411 8.00016 14.6666C6.33264 12.8411 5.38499 10.472 5.3335 7.99998C5.38499 5.528 6.33264 3.15888 8.00016 1.33331V1.33331Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const InfoIcon = ({ color = "#6280DA" }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.54984 1.66669H13.4498L18.3332 6.55002V13.45L13.4498 18.3334H6.54984L1.6665 13.45V6.55002L6.54984 1.66669Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10 6.66663V9.99996" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 13.3334H10.0083" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const UploadIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 7.5V9.5C10.5 9.76522 10.3946 10.0196 10.2071 10.2071C10.0196 10.3946 9.76522 10.5 9.5 10.5H2.5C2.23478 10.5 1.98043 10.3946 1.79289 10.2071C1.60536 10.0196 1.5 9.76522 1.5 9.5V7.5"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8.5 4L6 1.5L3.5 4" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 1.5V7.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
