import React, { useState } from "react";

import { Card, CardName, Row, Col, Label, PassInfo, Progress, Step } from "components";
import { TextFieldComponent } from "components/forms";
import { EyePassword } from "components/icons";

const iconStyle = {
  position: "absolute",
  top: 34,
  right: 20,
  zIndex: 2,
  width: 16,
  height: 16,
  cursor: "pointer",
};
const stepSize = { size: 12, mb: "11px" };
const halfSize = "calc(50% - 15px)";
const errorsArr = ["qwerty", "123", "abc", "Passw0rd", "ABC", "Password123"];
const marginSize = "0 0 24px";
const passProps = { padding: "0 40px 0 20px", isInvestor: true, autoComplete: "new-password" };

export const AccessInfo = ({ onChange, data: { password, confirmPassword, email }, fillError }) => {
  const [passType, setPasstype] = useState("password");
  const [confirmPassType, setConfirmPasstype] = useState("password");

  const passError =
    !/(?=.*[A-Z])/.test(password) ||
    password.length < 8 ||
    password.length > 30 ||
    !/(?=.*[^0-9A-Za-z])/.test(password) ||
    (password && errorsArr.map((str) => password.includes(str)).some((e) => e)) ||
    !/(?=.*[0-9])/.test(password);

  const onChangePassType = (cb, field) => {
    if (field === "password") cb("text");
    else cb("password");
  };

  return (
    <Card>
      <CardName>Admin Account Information</CardName>
      <Row m={marginSize}>
        <TextFieldComponent
          isInvestor
          placeholder="Admin e-mail"
          onChange={console.log}
          label="ADMIN E-mAIL"
          value={email}
          disabled
        />
      </Row>
      <Row a="flex-start">
        <Col w={halfSize}>
          <Row m={marginSize}>
            <TextFieldComponent
              {...passProps}
              label="password"
              placeholder="Password"
              type={passType}
              value={password}
              onChange={(e) => onChange("password", e.target.value)()}
              error={!password && fillError}
            />
            <EyePassword
              color={!password && fillError ? "#FF4343" : !password ? "#3333334d" : undefined}
              onClick={() => onChangePassType(setPasstype, passType)}
              style={iconStyle}
            />
          </Row>
          <Row>
            <TextFieldComponent
              {...passProps}
              label="CONFIRM PASSWORD"
              placeholder="Confirm password"
              type={confirmPassType}
              value={confirmPassword}
              onChange={(e) => onChange("confirmPassword", e.target.value)()}
              error={(confirmPassword && confirmPassword !== password) || (!confirmPassword && fillError)}
            />
            <EyePassword
              color={
                (confirmPassword && confirmPassword !== password) || (!confirmPassword && fillError)
                  ? "#FF4343"
                  : !confirmPassword
                  ? "#3333334d"
                  : undefined
              }
              onClick={() => onChangePassType(setConfirmPasstype, confirmPassType)}
              style={iconStyle}
            />
          </Row>
        </Col>
        <Col w={halfSize}>
          <Row a="flex-start">
            <Label error={password && passError}>Password Strength</Label>
            <Progress slim password={password} arr={errorsArr} />
          </Row>
          <PassInfo mt="0" p="15px 25px">
            <Step
              {...stepSize}
              key="symbol"
              value={password}
              regExp={/(?=.*[A-Z])/g}
              description="At least 1 uppercase character"
            />
            <Step
              {...stepSize}
              key="character"
              value={password}
              regExp={/(?=.*[^0-9A-Za-z])/g}
              description="At least 1 special character (!,%,?, etc)"
            />
            <Step
              {...stepSize}
              key="combination"
              value={password}
              isList
              arr={errorsArr}
              regExp={/(?=.*[^qwerty])(?=.*[^123])(?=.*[^abcd])/g}
              description="No often-used combinations"
            />
            <Step
              {...stepSize}
              key="number"
              value={password}
              regExp={/(?=.*[0-9])/g}
              description="Contain at least 1 number (0-9)"
            />
          </PassInfo>
        </Col>
      </Row>
    </Card>
  );
};
