import React from "react";

import { Card, CardName, Row, Text, Col, Label } from "components";
import { TypeComponent } from "components/forms";

export const Privacy = ({ onChange, data: { privatePlatform } }) => {
  const types = {
    public: {
      heading: "Public",
      description: "Any user can sign-up, create an account and get your validation to login to your platform.",
    },
    private: {
      heading: "Private",
      description: "User can only be invited by the admin to login to your platform.",
    },
  };

  const onPrivateChange = () => onChange("privatePlatform", !privatePlatform)();

  const type = privatePlatform ? "private" : "public";
  return (
    <Card>
      <CardName>Privacy</CardName>
      <Label>Choose website Privacy type</Label>
      <Row m="0 0 24px">
        <Col w="calc(50% - 15px)">
          <TypeComponent active={!privatePlatform} value="public" label="Free access" onChange={onPrivateChange} />
        </Col>
        <Col w="calc(50% - 15px)">
          <TypeComponent active={privatePlatform} value="private" label="Private" onChange={onPrivateChange} />
        </Col>
      </Row>
      <CardName>{types[type].heading}</CardName>

      <Text m="-10px 0 0 0">{types[type].description}</Text>
    </Card>
  );
};
