import React from "react";

import { ContainerFlex, InputLabel, DocInput, DocTextArea, TypeContainer, Circle, Type } from "./styled";

export const TextFieldComponent = ({
  onChange,
  width = "100%",
  label,
  placeholder,
  defaultValue = "",
  type = "text",
  disabled = false,
  height,
  fontSize,
  labelFontSize,
  error = false,
  errorWithBG,
  pseudo = "",
  isInvestor,
  autoComplite = "new-password",
  value,
  ref,
  noLabel = false,
  children,
  content,
  hiddenLabel,
  maxLength,
  ...otherProps
}) => {
  return (
    <ContainerFlex w={width} content={content}>
      {noLabel ? null : (
        <InputLabel hidden={hiddenLabel} labelFsize={labelFontSize} isInvestor={isInvestor}>
          {label}
        </InputLabel>
      )}
      <DocInput
        data-auto-focus
        autoComplete={autoComplite}
        errorWithBG={errorWithBG}
        error={error}
        fontSize={fontSize}
        height={height}
        type={type}
        width="100%"
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        pseudo={pseudo}
        ref={ref}
        maxLength={maxLength}
        {...otherProps}
      />
      {children}
    </ContainerFlex>
  );
};

export const TextAreaComponent = ({
  onChange,
  width = "100%",
  label,
  placeholder,
  defaultValue = "",
  areaHeight,
  labelFsize,
  fSize,
  isInvestor,
  name,
  error = false,
  maxLength,
}) => {
  return (
    <ContainerFlex w={width}>
      <InputLabel labelFsize={labelFsize} isInvestor={isInvestor}>
        {label}
      </InputLabel>
      <DocTextArea
        fSize={fSize}
        areaHeight={areaHeight}
        type="text"
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
        name={name}
        error={error}
        maxLength={maxLength}
      />
    </ContainerFlex>
  );
};

export const TypeComponent = ({ value, label, onChange, active }) => {
  const onChoose = () => {
    onChange(value);
  };
  return (
    <TypeContainer active={active} onClick={onChoose}>
      <Circle active={active} />
      <Type active={active}>{label}</Type>
    </TypeContainer>
  );
};
