import styled from "styled-components";

export const Back = styled.div`
  width: 200px;
  height: 32px;
  border: 1px solid #333333;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #333333;
  cursor: pointer;
  > svg {
    margin-right: 7px;
    path {
      stroke: #333333;
    }
  }
`;

export const Empty = styled.div`
  width: 200px;
`;

export const PrevHeader = styled.div`
  height: 80px;
  background: #ffcc01;
  box-sizing: border-box;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PrevHeading = styled.h5`
  font-size: 18px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #333333;
  margin: 0;
`;

export const WrapperPreview = styled.section`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
`;

export const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100vw;
  min-height: calc(100vh - 80px);
`;

const Column = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
`;

export const PreviewLeft = styled(Column)`
  width: 470px;
  padding: 30px 50px 60px;
  background: url(${({ bg }) => bg}), #1f263ec7;
  background-blend-mode: soft-light, normal;
  background-position: center;
  background-size: cover;
`;

export const Logo = styled.img`
  width: 325px;
  height: 75px;
  margin-bottom: 21px;
`;

export const Text = styled.p`
  font-weight: ${({ fWeight = "normal" }) => fWeight};
  font-size: ${({ size = "18" }) => `${size}px`};
  opacity: ${({ o = 1 }) => o};
  margin: ${({ m = "0 0 20px" }) => m};
  line-height: ${({ lHeight = 145 }) => `${lHeight}%`};
  color: #ffffff;
`;

export const BottomBlock = styled(Column)`
  margin-top: auto;
`;

export const Row = styled.div`
  display: flex;
`;

export const FormBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 30px 50px;
  width: calc(100% - 470px);
  background-color: #fff;
`;

export const Heading = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 135%;
  margin: 0 0 40px 0;
  color: #181818;
`;

export const Center = styled.div`
  margin: auto;
`;
