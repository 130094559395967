import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

import { Btn as Button } from "components";
import { ReactComponent as CloseIcon } from "assets/x-circle.svg";

const Background = styled.section`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: ${({ bg = "rgba(0, 0, 0, 0.15)" }) => bg};
  backdrop-filter: blur(5px);
  display: flex;
  z-index: 1001;
`;

const Wrapper = styled.div`
  width: ${({ width = false }) => (width ? width : "fit-content")};
  min-width: 400px;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  background: ${({ bg = "#ffffff" }) => bg};
  border: 1px solid ${({ borderColor = "#f9f9f9" }) => borderColor};
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  margin: auto;
`;

const Header = styled.div`
  height: 52px;
  width: 100%;
  padding: 0 25px;
  background: #ffffff;
  border: 1px solid #f9f9f9;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.08);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
`;

const Content = styled.div`
  width: 100%;
  padding: 25px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: ${({ align = "center" }) => align};
`;

const Heading = styled.h5`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  margin: 0;
  color: #181818;
  text-transform: none;
`;

const Text = styled.p`
  text-transform: none;
  margin: ${({ noMargin, margin }) => (noMargin ? 0 : margin ? margin : "15px 0 30px")};
  font-style: normal;
  color: #333333;
  font-weight: ${({ bold }) => (bold ? 600 : "normal")};
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "18px")};
  line-height: 150%;
  text-align: ${({ textAlign = "center" }) => textAlign};
`;

export const ErrorMessagePortal = ({ onClose, er: { message }, width, globalError = false }) => {
  return ReactDOM.createPortal(
    <Background>
      <Wrapper width={width} onClick={(e) => e.stopPropagation()}>
        <Header>
          <Heading>Error</Heading>
          <CloseIcon onClick={onClose} />
        </Header>
        <Content>
          <Text
            fSize={12}
            dangerouslySetInnerHTML={{ __html: message.replace("java.lang.IllegalArgumentException: ", "") }}
          />
          {!globalError ? (
            <Button width={200} height={36} onClick={onClose}>
              CLOSE
            </Button>
          ) : null}
        </Content>
      </Wrapper>
    </Background>,
    document.getElementById("globalModal500Error")
  );
};
