import config from "config";

const current = process.env.REACT_APP_CURRENT;

export default class Api {
  static url = config[current].url;

  static getInitial(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/registration/initial/checkFreemiumLink?token=${token}`);

      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static registerCompany(data) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/registration/initial/registrationFreemiumCompany`, {
        method: "POST",
        body: formData,
      });

      if (result.status === 200) res(true);
      else rej(await result.json());
    });
  }
}
