export const domains = [
  "com",
  "ad",
  "ag",
  "ai",
  "am",
  "an",
  "aq",
  "as",
  "aw",
  "ax",
  "az",
  "bd",
  "be",
  "bf",
  "bg",
  "bi",
  "bj",
  "bm",
  "bt",
  "bv",
  "bw",
  "by",
  "cc",
  "cd",
  "cf",
  "cg",
  "ch",
  "ci",
  "cl",
  "cm",
  "cu",
  "cv",
  "cx",
  "cz",
  "de",
  "dj",
  "dk",
  "dm",
  "ee",
  "eu",
  "fi",
  "fm",
  "fo",
  "ga",
  "gb",
  "gd",
  "ge",
  "gf",
  "gi",
  "gl",
  "gm",
  "gp",
  "gq",
  "gs",
  "gw",
  "gy",
  "hm",
  "hn",
  "hr",
  "ht",
  "hu",
  "ie",
  "im",
  "io",
  "is",
  "jm",
  "kg",
  "kp",
  "la",
  "lc",
  "li",
  "ls",
  "lt",
  "lu",
  "md",
  "mh",
  "mm",
  "mp",
  "mq",
  "mr",
  "ms",
  "nc",
  "ne",
  "nl",
  "no",
  "nu",
  "pf",
  "pg",
  "pm",
  "pn",
  "si",
  "sj",
  "sk",
  "sm",
  "so",
  "sr",
  "su",
  "tc",
  "td",
  "tg",
  "tk",
  "tl",
  "tm",
  "to",
  "tp",
  "tv",
  "uz",
  "va",
  "vc",
  "vg",
  "vu",
  "wf",
  "ws",
  "yt",
  "zw",
  "ac.zw",
  "gov.zw",
  "org.zw",
  "co.zw",
  "ac.zm",
  "sch.zm",
  "gov.zm",
  "org.zm",
  "co.zm",
  "web.za",
  "tm.za",
  "ngo.za",
  "net.za",
  "alt.za",
  "school.za",
  "org.za",
  "nom.za",
  "mil.za",
  "law.za",
  "gov.za",
  "edu.za",
  "co.za",
  "city.za",
  "ac.za",
  "edu.yu",
  "org.yu",
  "co.yu",
  "ac.yu",
  "others?",
  "net.ye",
  "com.ye",
  "health.vn",
  "pro.vn",
  "name.vn",
  "info.vn",
  "biz.vn",
  "ac.vn",
  "int.vn",
  "gov.vn",
  "edu.vn",
  "org.vn",
  "net.vn",
  "com.vn",
  "vn",
  "gov.vi",
  "edu.vi",
  "org.vi",
  "com.vi",
  "vi",
  "web.ve",
  "co.ve",
  "info.ve",
  "org.ve",
  "net.ve",
  "com.ve",
  "mil.uy",
  "net.uy",
  "com.uy",
  "org.uy",
  "gub.uy",
  "edu.uy",
  "wy.us",
  "wv.us",
  "wi.us",
  "wa.us",
  "va.us",
  "vt.us",
  "ut.us",
  "tx.us",
  "tn.us",
  "sd.us",
  "sc.us",
  "ri.us",
  "pa.us",
  "or.us",
  "ok.us",
  "oh.us",
  "ny.us",
  "nv.us",
  "nsn.us",
  "nm.us",
  "nj.us",
  "nh.us",
  "ne.us",
  "nd.us",
  "nc.us",
  "mt.us",
  "ms.us",
  "mo.us",
  "mn.us",
  "mi.us",
  "me.us",
  "md.us",
  "ma.us",
  "la.us",
  "ky.us",
  "ks.us",
  "kids.us",
  "isa.us",
  "in.us",
  "il.us",
  "id.us",
  "ia.us",
  "hi.us",
  "ga.us",
  "fl.us",
  "fed.us",
  "dni.us",
  "de.us",
  "dc.us",
  "ct.us",
  "co.us",
  "ca.us",
  "az.us",
  "ar.us",
  "al.us",
  "ak.us",
  "us",
  "domains",
  "level",
  "4th",
  "uses",
  "parliament.uk<br>.sch.uk",
  "national-library-scotland.uk",
  "nls.uk",
  "nel.uk",
  "jet.uk",
  "icnet.uk",
  "british-library.uk",
  "bl.uk",
  // "sch.uk",
  "police.uk",
  "plc.uk",
  "org.uk",
  "nhs.uk",
  "nic.uk",
  "net.uk",
  "mod.uk",
  "mil.uk",
  "me.uk",
  "ltd.uk",
  "gov.uk",
  "co.uk",
  "<p>.ac.uk",
  "or.ug",
  "ne.ug",
  "go.ug",
  "sc.ug",
  "ac.ug",
  "co.ug",
  "ug",
  "zt.ua",
  "zhitomir.ua",
  "zp.ua",
  "zaporizhzhe.ua",
  "vn.ua",
  "vinnica.ua",
  "uzhgorod.ua",
  "te.ua",
  "ternopil.ua",
  "sumy.ua",
  "sebastopol.ua",
  "rv.ua",
  "rovno.ua",
  "pl.ua",
  "poltava.ua",
  "od.ua",
  "odessa.ua",
  "mk.ua",
  "nikolaev.ua",
  "lviv.ua",
  "lutsk.ua",
  "lg.ua",
  "lugansk.ua",
  "kr.ua",
  "kirovograd.ua",
  "kv.ua",
  "kiev.ua",
  "km.ua",
  "khmelnitskiy.ua",
  "ks.ua",
  "kherson.ua",
  "kh.ua",
  "kharkov.ua",
  "if.ua",
  "ivano-frankivsk.ua",
  "dn.ua",
  "donetsk.ua",
  "dp.ua",
  "dnepropetrovsk.ua",
  "crimea.ua",
  "cv.ua",
  "chernovtsy.ua",
  "cn.ua",
  "chernigov.ua",
  "ck.ua",
  "cherkassy.ua",
  "names:",
  "org.ua<br>Geographical",
  "edu.ua",
  "net.ua",
  "gov.ua",
  "com.ua",
  "ua",
  "ne.tz",
  "or.tz",
  "go.tz",
  "ac.tz",
  "co.tz",
  "商業.tw",
  "組織.tw",
  "網路.tw",
  "club.tw",
  "ebiz.tw",
  "game.tw",
  "idv.tw",
  "org.tw",
  "net.tw",
  "com.tw",
  "mil.tw",
  "gov.tw",
  "edu.tw",
  "tw",
  "others?",
  "gov.tv",
  "tv",
  "one",
  "as",
  "acts",
  "but",
  "domain,",
  "2nd-level",
  "official",
  "an",
  "not",
  "is",
  "us.tt",
  "gov.tt",
  "edu.tt",
  "name.tt",
  "pro.tt",
  "info.tt",
  "biz.tt",
  "net.tt",
  "org.tt",
  "com.tt",
  "co.tt",
  "tt",
  "bel.tr",
  "edu.tr",
  "k12.tr",
  "mil.tr",
  "pol.tr",
  "bel.tr",
  "gov.tr",
  "tel.tr",
  "name.tr",
  "bbs.tr",
  "dr.tr",
  "av.tr",
  "gen.tr",
  "web.tr",
  "org.tr",
  "net.tr",
  "biz.tr",
  "info.tr",
  "com.tr",
  "gov.tp",
  "tp",
  "gov.to",
  "to",
  "net.tn",
  "fin.tn",
  "ens.tn",
  "info.tn",
  "tourism.tn",
  "nat.tn",
  "ind.tn",
  "org.tn",
  "gov.tn",
  "intl.tn",
  "com.tn",
  "mil.tj",
  "go.tj",
  "gov.tj",
  "web.tj",
  "org.tj",
  "net.tj",
  "name.tj",
  "int.tj",
  "edu.tj",
  "co.tj",
  "com.tj",
  "biz.tj",
  "ac.tj",
  "tj",
  "net.th",
  "or.th",
  "mi.th",
  "go.th",
  "in.th",
  "co.th",
  "ac.th",
  "others?",
  "net.sy",
  "com.sy",
  "gov.sy",
  "red.sv",
  "org.sv",
  "gob.sv",
  "com.sv",
  "edu.sv",
  "idn.sg",
  "per.sg",
  "edu.sg",
  "gov.sg",
  "org.sg",
  "net.sg",
  "com.sg",
  "sg",
  "bd.se",
  "ac.se",
  "z.se",
  "y.se",
  "x.se",
  "w.se",
  "u.se",
  "t.se",
  "s.se",
  "o.se",
  "n.se",
  "m.se",
  "k.se",
  "i.se",
  "h.se",
  "g.se",
  "f.se",
  "e.se",
  "d.se",
  "c.se",
  "ab.se",
  "mil.se<br>Geographical:",
  "fh.se",
  "fhsk.se",
  "fhv.se",
  "sshn.se",
  "naturbruksgymn.se",
  "lanbib.se",
  "lanarb.se",
  "komvux.se",
  "kommunalforbund.se",
  "komforb.se",
  "press.se",
  "parti.se",
  "brand.se",
  "tm.se",
  "pp.se",
  "org.se",
  "se",
  "info.sd",
  "gov.sd",
  "tv.sd",
  "med.sd",
  "edu.sd",
  "org.sd",
  "net.sd",
  "com.sd",
  "sd",
  "edu.sc",
  "org.sc",
  "net.sc",
  "gov.sc",
  "com.sc",
  "sc",
  "others?",
  "edu.sb",
  "net.sb",
  "gov.sb",
  "com.sb",
  "pub.sa",
  "org.sa",
  "net.sa",
  "gov.sa",
  "med.sa",
  "sch.sa",
  "edu.sa",
  "com.sa",
  "gouv.rw",
  "mil.rw",
  "int.rw",
  "co.rw",
  "com.rw",
  "ac.rw",
  "edu.rw",
  "net.rw",
  "gov.rw",
  "rw",
  "others!!!",
  "ac.ru",
  "int.ru",
  "msk.ru",
  "pp.ru",
  "org.ru",
  "net.ru",
  "com.ru",
  "ru",
  "www.ro",
  "store.ro",
  "firm.ro",
  "arts.ro",
  "rec.ro",
  "info.ro",
  "nom.ro",
  "nt.ro",
  "tm.ro",
  "org.ro",
  "com.ro",
  "ro",
  "com.py",
  "edu.py",
  "gov.py",
  "org.py",
  "net.py",
  "publ.pt",
  "org.pt",
  "nome.pt",
  "net.pt",
  "int.pt",
  "gov.pt",
  "edu.pt",
  "com.pt",
  "pt",
  "net.ps",
  "org.ps",
  "com.ps",
  "plo.ps",
  "sec.ps",
  "gov.ps",
  "edu.ps",
  "ps",
  "cpa.pro",
  "med.pro",
  "law.pro",
  "pro.pr",
  "org.pr",
  "net.pr",
  "name.pr",
  "isla.pr",
  "info.pr",
  "gov.pr",
  "edu.pr",
  "com.pr",
  "biz.pr",
  "pr",
  "more...</a>",
  'List:.pl">and',
  'title="TLD',
  'href="/TLD_List:.pl"',
  "<a",
  "olsztyn.pl.torun.pl",
  "bialystok.pl",
  "lublin.pl",
  "szczecin.pl",
  "slupsk.pl",
  "gdansk.pl",
  "gda.pl",
  "lodz.pl",
  "poznan.pl",
  "krakow.pl",
  "wroclaw.pl",
  "wroc.pl",
  "warszawa.pl",
  "waw.pl",
  "<br>Geographic:",
  "mil.pl",
  "info.pl",
  "gov.pl",
  "ngo.pl",
  "org.pl",
  "edu.pl",
  "art.pl",
  "net.pl",
  "biz.pl",
  "com.pl",
  "pl",
  "gos.pk",
  "gop.pk",
  "gon.pk",
  "gok.pk",
  "gob.pk",
  "gov.pk",
  "web.pk",
  "biz.pk",
  "fam.pk",
  "org.pk",
  "edu.pk",
  "net.pk",
  "com.pk",
  "pk",
  "others?",
  "gov.ph",
  "com.ph",
  "ph",
  "net.pg",
  "com.pg",
  "edu.pf",
  "org.pf",
  "com.pf",
  "pf",
  "nom.pe",
  "gob.pe",
  "mil.pe",
  "edu.pe",
  "net.pe",
  "org.pe",
  "com.pe",
  "nom.pa",
  "med.pa",
  "ing.pa",
  "abo.pa",
  "net.pa",
  "org.pa",
  "edu.pa",
  "gob.pa",
  "sld.pa",
  "ac.pa",
  "com.pa",
  "med.om",
  "pro.om",
  "biz.om",
  "museum.om",
  "mil.om",
  "org.om",
  "net.om",
  "gov.om",
  "sch.om",
  "ac.com",
  "edu.om",
  "co.om",
  "com.om",
  "school.nz",
  "org.nz",
  "net.nz",
  "mil.nz",
  "maori.nz",
  "iwi.nz",
  "govt.nz",
  "geek.nz",
  "gen.nz",
  "cri.nz",
  "co.nz",
  "ac.nz",
  "one",
  "like",
  "acts",
  "but",
  "TLD,",
  "official",
  "a",
  "not",
  "is",
  "co.nr",
  "net.nr",
  "com.nr",
  "org.nr",
  "nr",
  "info.nr",
  "biz.nr",
  "edu.nr",
  "gov.nr",
  "nr",
  "mil.np",
  "gov.np",
  "net.np",
  "edu.np",
  "org.np",
  "com.np",
  "gs.[county].no",
  "[geo].no",
  "idrett.no",
  "folkebibl.no",
  "fylkesbibl.no",
  "museum.no",
  "fhs.no",
  "vgs.no",
  "priv.no",
  "herad.no",
  "kommune.no",
  "stat.no",
  "mil.no",
  "no",
  "000.nl/.999.nl",
  "nl",
  "net.ni",
  "nom.ni",
  "org.ni",
  "edu.ni",
  "com.ni",
  "gob.ni",
  "net.ng",
  "org.ng",
  "gov.ng",
  "com.ng",
  "edu.ng",
  "name.my",
  "mil.my",
  "edu.my",
  "gov.my",
  "org.my",
  "net.my",
  "com.my",
  "gob.mx",
  "edu.mx",
  "org.mx",
  "net.mx",
  "com.mx",
  "org.mw",
  "net.mw",
  "museum.mw",
  "int.mw",
  "gov.mw",
  "edu.mw",
  "coop.mw",
  "com.mw",
  "co.mw",
  "ac.mw",
  "pro.mv",
  "org.mv",
  "net.mv",
  "name.mv",
  "museum.mv",
  "mil.mv",
  "int.mv",
  "info.mv",
  "gov.mv",
  "edu.mv",
  "coop.mv",
  "com.mv",
  "biz.mv",
  "aero.mv",
  "others?",
  "co.mu",
  "com.mu",
  "mu",
  "net.mt",
  "edu.mt",
  "gov.mt",
  "com.mt",
  "org.mt",
  "mt",
  "music.mobi",
  "weather.mobi",
  "gov.mo",
  "edu.mo",
  "org.mo",
  "net.mo",
  "com.mo",
  "mo",
  "org.mk",
  "com.mk",
  "mk",
  "navy.mil",
  "army.mil",
  "net.mh",
  "mil.mg",
  "edu.mg",
  "com.mg",
  "tm.mg",
  "prd.mg",
  "gov.mg",
  "nom.mg",
  "org.mg",
  "mg",
  "asso.mc",
  "tm.mc",
  "mc",
  "org.ma",
  "gov.ma",
  "net.ma",
  "co.ma",
  "ma",
  "id.ly",
  "org.ly",
  "med.ly",
  "sch.ly",
  "edu.ly",
  "plc.ly",
  "gov.ly",
  "net.ly",
  "com.ly",
  "ly",
  "conf.lv",
  "asn.lv",
  "net.lv",
  "id.lv",
  "mil.lv",
  "org.lv",
  "gov.lv",
  "edu.lv",
  "com.lv",
  "lv",
  "net.lu",
  "org.lu",
  "mil.lu",
  "gov.lu",
  "lu",
  "mil.lt",
  "gov.lt",
  "lt",
  "co.ls",
  "org.ls",
  "net.lr",
  "org.lr",
  "gov.lr",
  "edu.lr",
  "com.lr",
  "hotel.lk",
  "grp.lk",
  "assn.lk",
  "ltd.lk",
  "web.lk",
  "soc.lk",
  "ngo.lk",
  "edu.lk",
  "org.lk",
  "com.lk",
  "int.lk",
  "net.lk",
  "sch.lk",
  "gov.lk",
  "lk",
  "gov.li",
  "org.li",
  "net.li",
  "com.li",
  "li",
  "gov.lc",
  "edu.lc",
  "org.lc",
  "com.lc",
  "com.lb",
  "edu.lb",
  "gov.lb",
  "org.lb",
  "net.lb",
  "com.kz",
  "mil.kz",
  "gov.kz",
  "net.kz",
  "edu.kz",
  "org.kz",
  "net.ky",
  "org.ky",
  "com.ky",
  "gov.ky",
  "edu.ky",
  "ky",
  "mil.kw",
  "org.kw",
  "net.kw",
  "gov.kw",
  "edu.kw",
  "com.kw",
  "or.kr",
  "co.kr",
  "kr",
  "org.kh",
  "net.kh",
  "mil.kh",
  "gov.kh",
  "edu.kh",
  "com.kh",
  "per.kh",
  "kitakyushu.jp",
  "kobe.jp",
  "nagoya.jp",
  "kawasaki.jp",
  "yokohama.jp",
  "sendai.jp",
  "sapporo.jp",
  "okinawa.jp",
  "kagoshima.jp",
  "miyazaki.jp",
  "oita.jp",
  "kumamoto.jp",
  "nagasaki.jp",
  "saga.jp",
  "fukuoka.jp",
  "kochi.jp",
  "ehime.jp",
  "kagawa.jp",
  "tokushima.jp",
  "yamaguchi.jp",
  "hiroshima.jp",
  "okayama.jp",
  "shimane.jp",
  "tottori.jp",
  "wakayama.jp",
  "nara.jp",
  "hyogo.jp",
  "osaka.jp",
  "kyoto.jp",
  "shiga.jp",
  "mie.jp",
  "aichi.jp",
  "shizuoka.jp",
  "gifu.jp",
  "nagano.jp",
  "yamanashi.jp",
  "fukui.jp",
  "ishikawa.jp",
  "toyama.jp",
  "niigata.jp",
  "kanagawa.jp",
  "tokyo.jp",
  "chiba.jp",
  "saitama.jp",
  "gunma.jp",
  "tochigi.jp",
  "ibaraki.jp",
  "fukushima.jp",
  "yamagata.jp",
  "akita.jp",
  "miyagi.jp",
  "iwate.jp",
  "aomori.jp",
  "hokkaido.jp",
  "or.jp<br>Geo-names:",
  "ne.jp",
  "lg.jp",
  "gr.jp",
  "go.jp",
  "ed.jp",
  "co.jp",
  "ad.jp",
  "ac.jp",
  "jp",
  "mil.jo",
  "gov.jo",
  "edu.jo",
  "net.jo",
  "org.jo",
  "com.jo",
  "jo",
  "org.jm",
  "net.jm",
  "com.jm",
  "gov.jm",
  "edu.jm",
  "org.je",
  "net.je",
  "co.je",
  "je",
  "others",
  "gov.it",
  "it",
  "sch.ir",
  "org.ir",
  "net.ir",
  "gov.ir",
  "co.ir",
  "ac.ir",
  "ir",
  "mil.in",
  "gov.in",
  "res.in",
  "edu.in",
  "ac.in",
  "nic.in",
  "ind.in",
  "gen.in",
  "org.in",
  "net.in",
  "firm.in",
  "co.in",
  "in",
  "ac.im",
  "nic.im",
  "org.im",
  "gov.im",
  "net.im",
  "plc.co.im",
  "ltd.co.im",
  "co.im",
  "idf.il",
  "muni.il",
  "gov.il",
  "k12.il",
  "net.il",
  "org.il",
  "co.il",
  "ac.il",
  "gov.ie",
  "ie",
  "go.id",
  "or.id",
  "co.id",
  "ac.id",
  "video.hu",
  "utazas.hu",
  "tozsde.hu",
  "szex.hu",
  "suli.hu",
  "shop.hu",
  "sex.hu",
  "reklam.hu",
  "news.hu",
  "media.hu",
  "lakas.hu",
  "konyvelo.hu",
  "jogasz.hu",
  "ingatlan.hu",
  "hotel.hu",
  "games.hu",
  "forum.hu",
  "film.hu",
  "erotika.hu",
  "erotica.hu",
  "city.hu",
  "casino.hu",
  "bolt.hu",
  "agrar.hu",
  "2000.hu",
  "tm.hu",
  "sport.hu",
  "priv.hu",
  "org.hu",
  "info.hu",
  "co.hu",
  "hu",
  "gouv.ht",
  "edu.ht",
  "med.ht",
  "coop.ht",
  "perso.ht",
  "asso.ht",
  "rel.ht",
  "pol.ht",
  "art.ht",
  "org.ht",
  "adult.ht",
  "pro.ht",
  "info.ht",
  "shop.ht",
  "firm.ht",
  "net.ht",
  "com.ht",
  "ht",
  "com.hr",
  "name.hr",
  "from.hr",
  "iz.hr",
  "hr",
  "gob.hn",
  "mil.hn",
  "net.hn",
  "org.hn",
  "edu.hn",
  "com.hn",
  "hn",
  "org.hk",
  "net.hk",
  "idv.hk",
  "gov.hk",
  "edu.hk",
  "com.hk",
  "hk",
  "gov.gr",
  "org.gr",
  "net.gr",
  "edu.gr",
  "com.gr",
  "gr",
  "org.gp",
  "or",
  "asso.gp,",
  "edu.gp,",
  "net.gp,",
  "com.gp,",
  "gp",
  "net.gn",
  "org.gn",
  "gov.gn",
  "ac.gn",
  "com.gn",
  "org.gi",
  "edu.gi",
  "mod.gi",
  "gov.gi",
  "ltd.gi",
  "com.gi",
  "gi",
  "mil.gh",
  "org.gh",
  "gov.gh",
  "edu.gh",
  "com.gh",
  "org.gg",
  "net.gg",
  "co.gg",
  "gg",
  "pvt.ge",
  "net.ge",
  "mil.ge",
  "org.ge",
  "gov.ge",
  "edu.ge",
  "com.ge",
  "ge",
  "gouv.fr",
  "com.fr",
  "presse.fr",
  "prd.fr",
  "nom.fr",
  "asso.fr",
  "tm.fr",
  "fr",
  "net.fk",
  "nom.fk",
  "ac.fk",
  "gov.fk",
  "org.fk",
  "co.fk",
  "school.fj",
  "mil.fj",
  "gov.fj",
  "ac.fj",
  "pro.fj",
  "org.fj",
  "net.fj",
  "name.fj",
  "info.fj",
  "com.fj",
  "biz.fj",
  "aland.fi",
  "fi",
  "info.et",
  "name.et",
  "biz.et",
  "net.et",
  "edu.et",
  "org.et",
  "gov.et",
  "com.et",
  "edu.es",
  "gob.es",
  "org.es",
  "nom.es",
  "com.es",
  "es",
  "mil.eg",
  "net.eg",
  "org.eg",
  "com.eg",
  "gov.eg",
  "sci.eg",
  "edu.eg",
  "eun.eg",
  "pri.ee",
  "fie.ee",
  "org.ee",
  "com.ee",
  "ee",
  "mil.ec",
  "gov.ec",
  "edu.ec",
  "org.ec",
  "pro.ec",
  "med.ec",
  "fin.ec",
  "net.ec",
  "info.ec",
  "com.ec",
  "ec",
  "art.dz",
  "pol.dz",
  "asso.dz",
  "edu.dz",
  "gov.dz",
  "net.dz",
  "org.dz",
  "com.dz",
  "dz",
  "art.do",
  "mil.do",
  "net.do",
  "web.do",
  "sld.do",
  "org.do",
  "com.do",
  "gob.do",
  "gov.do",
  "edu.do",
  "gov.dm",
  "edu.dm",
  "org.dm",
  "net.dm",
  "com.dm",
  "dm",
  "parliament.cy",
  "press.cy",
  "ekloges.cy",
  "ac.cy",
  "tm.cy",
  "name.cy",
  "org.cy",
  "net.cy",
  "pro.cy",
  "ltd.cy",
  "info.cy",
  "biz.cy",
  "com.cy",
  "gov.cx",
  "cx",
  "inf.cu",
  "gov.cu",
  "net.cu",
  "org.cu",
  "edu.cu",
  "com.cu",
  "cu",
  "sa.cr",
  "or.cr",
  "go.cr",
  "fi.cr",
  "ed.cr",
  "co.cr",
  "ac.cr",
  "us.com",
  "nom.co",
  "net.co",
  "mil.co",
  "gov.co",
  "org.co",
  "edu.co",
  "com.co",
  "zj.cn",
  "yn.cn",
  "xz.cn",
  "xj.cn",
  "tj.cn",
  "sx.cn",
  "sn.cn",
  "sh.cn",
  "sd.cn",
  "sc.cn",
  "qh.cn",
  "nx.cn",
  "nm.cn",
  "ln.cn",
  "jx.cn",
  "js.cn",
  "jl.cn",
  "hn.cn",
  "hl.cn",
  "hi.cn",
  "he.cn",
  "hb.cn",
  "ha.cn",
  "gx.cn",
  "gz.cn",
  "gs.cn",
  "gd.cn",
  "fj.cn",
  "cq.cn",
  "bj.cn",
  "ah.cn",
  "org.cn",
  "net.cn",
  "gov.cn",
  "edu.cn",
  "com.cn",
  "ac.cn",
  "cn",
  "others",
  "and",
  "co.ck",
  "gov.ch",
  "org.ch",
  "net.ch",
  "com.ch",
  "ch",
  "org.cd",
  "net.cd",
  "com.cd",
  "cd",
  "tld",
  "a",
  "as",
  "sells",
  "co.cc",
  "co.cc",
  "cc",
  "yk.ca",
  "sk.ca",
  "qc.ca",
  "pe.ca",
  "on.ca",
  "nu.ca",
  "nt.ca",
  "ns.ca",
  "nl.ca",
  "nf.ca",
  "nb.ca",
  "mb.ca",
  "bc.ca",
  "ab.ca",
  "ca",
  "mil.by",
  "gov.by",
  "org.bw",
  "co.bw",
  "bw",
  "org.bt",
  "net.bt",
  "gov.bt",
  "edu.bt",
  "com.bt",
  "bt",
  "org.bs",
  "net.bs",
  "com.bs",
  "bs",
  "nom.br",
  "dpn.br",
  "zlg.br",
  "vet.br",
  "trd.br",
  "slg.br",
  "qsl.br",
  "psc.br",
  "pro.br",
  "ppg.br",
  "odo.br",
  "ntr.br",
  "not.br",
  "mus.br",
  "med.br",
  "mat.br",
  "lel.br",
  "jor.br",
  "ggf.br",
  "fst.br",
  "fot.br",
  "fnd.br",
  "eti.br",
  "eng.br",
  "ecn.br",
  "cnt.br",
  "cng.br",
  "cim.br",
  "bmd.br",
  "bio.br",
  "ato.br",
  "arq.br",
  "adv.br",
  "adm.br",
  "etc.br",
  "tv.br",
  "tur.br",
  "tmp.br",
  "srv.br",
  "rec.br",
  "psi.br",
  "org.br",
  "net.br",
  "mil.br",
  "inf.br",
  "ind.br",
  "imb.br",
  "gov.br",
  "g12.br",
  "fm.br",
  "far.br",
  "esp.br",
  "coop.br",
  "com.br",
  "edu.br",
  "art.br",
  "am.br",
  "agr.br",
  "int.bo",
  "mil.bo",
  "tv.bo",
  "edu.bo",
  "gob.bo",
  "gov.bo",
  "net.bo",
  "org.bo",
  "com.bo",
  "bo",
  "net.bn",
  "org.bn",
  "edu.bn",
  "com.bn",
  "net.bm",
  "gov.bm",
  "org.bm",
  "edu.bm",
  "com.bm",
  "possibly",
  "mil.bd",
  "org.bd",
  "gov.bd",
  "net.bd",
  "edu.bd",
  "com.bd",
  "org.bb",
  "net.bb",
  "gov.bb",
  "edu.bb",
  "com.bb",
  "info.az",
  "name.az",
  "pp.az",
  "mil.az",
  "edu.az",
  "org.az",
  "biz.az",
  "gov.az",
  "int.az",
  "net.az",
  "com.az",
  "az",
  "wa",
  "vic",
  "tas",
  "sa",
  "qld",
  "nt",
  "nsw",
  "act",
  "edu.au:",
  "and",
  "gov.au",
  "wa.au<br>For",
  "vic.au",
  "tas.au",
  "sa.au",
  "qld.au",
  "nt.au",
  "nsw.au",
  "act.au",
  "conf.au",
  "info.au",
  "oz.au",
  "csiro.au",
  "org.au",
  "id.au",
  "net.au",
  "com.au",
  "asn.au",
  "priv.at",
  "or.at",
  "co.at",
  "ac.at",
  "gv.at",
  "at",
  "urn.arpa",
  "uri.arpa",
  "ip6.arpa",
  "iris.arpa",
  "in-addr.arpa",
  "e164.arpa",
  "org.ar",
  "net.ar",
  "mil.ar",
  "int.ar",
  "gov.ar",
  "com.ar",
  "pb.ao",
  "og.ao",
  "it.ao",
  "gv.ao",
  "ed.ao",
  "co.ao",
  "edu.an",
  "org.an",
  "net.an",
  "com.an",
  "an",
  "inima.al",
  "upt.al",
  "soros.al",
  "tirana.al",
  "uniti.al",
  "net.al",
  "com.al",
  "org.al",
  "edu.al",
  "gov.al",
  "org.ai",
  "net.ai",
  "com.ai",
  "off.ai",
  "ai",
  "nom.ag",
  "co.ag",
  "net.ag",
  "org.ag",
  "com.ag",
  "ag",
  "com.af",
  "net.af",
  "edu.af",
  "gov.af",
  "af",
  "name.ae",
  "pro.ae",
  "ac.ae",
  "sch.ae",
  "mil.ae",
  "org.ae",
  "gov.ae",
  "net.ae",
  "ae",
  "org.ac",
  "mil.ac",
  "net.ac",
  "gov.ac",
  "edu.ac",
  "com.ac",
].reduce((domains, domain) => {
  if (domain.includes("."))
    return [...domains, domain.replace(".", "\\."), ...domain.split(".")];
  return [...domains, domain];
}, []);

export const http = ["http", "https"];
export const all = [...new Set([...domains, ...http])];

export function domainValidator(domain = "") {
  domain = domain || "";
  const res = all.find((e) => {
    const reg = new RegExp(`(\\.${e}$)|(^${e}$)|(^${e}\\.$)|(\\.${e})\\.`);
    return reg.test(domain);
  });
  return res;
}
