import React from "react";

import { Card, CardName, Row, Col, DomainPreview, Label, Info } from "components";
import { TextFieldComponent } from "components/forms";
import { Globe, InfoIcon } from "components/icons";
import config from "config";

import { domainValidator } from "components/cards/const";
const current = process.env.REACT_APP_CURRENT;

const marginSize = "0 0 24px";
const halfSize = "calc(50% - 15px)";

export const MainInfo = ({ onChange, data: { companyName, domain = "", favicon }, fillError }) => {
  return (
    <Card>
      <CardName>Main Information</CardName>
      <Row m={marginSize}>
        <TextFieldComponent
          value={companyName}
          isInvestor
          placeholder="Company Name"
          label="Company Name"
          onChange={(e) => onChange("companyName", e.target.value)()}
          error={!companyName && fillError}
        />
      </Row>
      <Row>
        <Col w={halfSize}>
          <TextFieldComponent
            isInvestor
            placeholder="yourplatform"
            label="PLATFORM DOMAIN"
            value={domain}
            error={(domain && (!domain.match(/^[a-zA-Z0-9][a-zA-Z0-9.-]+[a-zA-Z0-9]$/) || domainValidator(domain))) || (!domain && fillError)}
            onChange={(e) => onChange("domain", e.target.value)()}
          />
        </Col>
        <Col w={halfSize}>
          <Label>LINK PREVIEW</Label>
          <DomainPreview>
            {favicon ? <img src={favicon} alt="favicon" /> : <Globe />}
            <span>
              {domain || "yourplatform"}.<span className="valk-domain">{config[current].mainDomain}</span>
            </span>
          </DomainPreview>
        </Col>
      </Row>
      <Info>
        <InfoIcon />
        <div>Please check carefully the domain name because you will not be able to change it in the future.</div>
      </Info>
    </Card>
  );
};
