import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

const Layout = styled.section`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  top: 0;
  left: 0;
  display: flex;
  z-index: 1000;
`;

const loader1 = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const loader2 = keyframes`  
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }`;

const loader3 = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoaderContainer = styled.div`
  width: fit-content;
  height: fit-content;
  position: relative;
  margin: auto;
`;

const Circle = styled.div`
  display: block;
  height: 32px;
  width: 32px;
  -webkit-animation: ${loader1} 3s linear infinite;
  animation: ${loader1} 3s linear infinite;
  span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 32px;
    width: 32px;
    clip: rect(16px, 32px, 32px, 0);
    -webkit-animation: ${loader2} 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    animation: ${loader2} 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      height: 32px;
      width: 32px;
      border: 4px solid transparent;
      border-top: 4px solid #fff;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 50%;
      -webkit-animation: ${loader3} 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
      animation: ${loader3} 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      height: 32px;
      width: 32px;
      border: 4px solid #6280da;
      border-radius: 50%;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
  }
`;

export const Loader = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);

  return (
    <Layout>
      <LoaderContainer>
        <Circle>
          <span></span>
        </Circle>
      </LoaderContainer>
    </Layout>
  );
};
