import { css } from "styled-components";

export const fullVwVh = css`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
`;

export const borderBox = css`
  box-sizing: ${({ boxSizing = "border-box" }) => boxSizing};
`;

export const muliFont = css`
  font-size: ${({ fontSize = 14 }) => `${fontSize}px`};
  line-height: ${({ lineHeight = 135 }) => `${lineHeight}%`};
  font-style: normal;
  font-weight: normal;
`;

export const flexCenter = css`
  justify-content: center;
  align-items: center;
`;

export const column = css`
  display: flex;
  flex-direction: column;
`;
