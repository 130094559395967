import DEV from "./dev";
import PROD from "./prod";
import TEST from "./test";

const config = {
  DEV,
  PROD,
  TEST,
};

export default config;
