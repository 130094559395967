import React from "react";

import { AuthPreviev } from "./auth.layout";
import { PrevHeader, Back, PrevHeading, Empty, WrapperPreview } from "./styled";
import { ReactComponent as ArrowLeft } from "assets/arrowleft.svg";

export const Preview = ({ data, onClose }) => {
  return (
    <WrapperPreview>
      <PrevHeader>
        <Back onClick={onClose}>
          <ArrowLeft /> back to SET UP PAGE
        </Back>
        <PrevHeading>Welcome Page Preview</PrevHeading>
        <Empty />
      </PrevHeader>
      <AuthPreviev data={data} />
    </WrapperPreview>
  );
};
