import React, { useCallback, useEffect, useState } from "react";

import * as Components from "components";
import { UserFill } from "components/icons";
import { Loader } from "components/loader";
import { CreatePlatformLoader } from "components/animatedLoader";

import { AccessInfo } from "components/cards/AccessInfo";
import { PlatformDesign } from "components/cards/PlatformDesign";
import { Privacy } from "components/cards/Privacy";
import { MainInfo } from "components/cards/MainInfo";
import { Preview } from "components/preview";

import { ErrorMessagePortal } from "components/modal";

import Api from "api";
import { replaceEmpty, checkRequired } from "helpers";
import config from "config";

const current = process.env.REACT_APP_CURRENT;

const defaultState = {
  password: "",
  confirmPassword: "",
  email: "",
  companyName: "",
  domain: "",
  slogan: "",
  description: "",
  footerText: "",
  platformLogo: "",
  privatePlatform: false,
};

const App = () => {
  const [data, setData] = useState(null);
  const [{ token, email }, setInitial] = useState({});
  const [preview, setPreview] = useState(false);
  const [wait, setWait] = useState(false);
  const [error, setError] = useState(null);
  const [fillError, setFillError] = useState(false);

  const Help = useCallback(
    () => (
      <Components.Btn key="help" className="side-buttons" transparent width={205}>
        ASK FOR HELP
      </Components.Btn>
    ),
    [],
  );

  const User = useCallback(
    (username = "username") => (
      <Components.UserName key="user" className="side-buttons">
        <UserFill />
        <span>{username}</span>
      </Components.UserName>
    ),
    [],
  );

  const onChangePreview = () => setPreview(!preview);

  const onDataChange = (key, val) => () => setData({ ...data, [key]: val });

  useEffect(() => {
    Api.getInitial(window.location.hash.replace("#", ""))
      .then((res) => {
        setInitial(res);
        const { description, footerText, platformLogo, slogan, favicon } = res.freemeumCompany;
        if (favicon) {
          const iconTag = document.getElementById("favicon");
          iconTag.setAttribute("href", favicon);
        }
        setData({
          ...defaultState,
          email: res.email,
          description,
          footerText,
          platformLogo,
          slogan,
          favicon: favicon || null,
        });
      })
      .catch(setError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      if (!checkRequired(data)) {
        setFillError(false);
      }
    }
  }, [data]);

  const onSubmit = async () => {
    const _fillError = checkRequired(data);
    if (_fillError) {
      setFillError(true);
      window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
      return;
    }
    setWait(true);
    const _data = replaceEmpty(data);
    _data.token = token;
    const res = await Api.registerCompany(_data).catch((e) => {
      setWait(false);
      setError(e);
    });

    if (res) window.open(`https://${data.domain}.${config[current].mainDomain}`, "_self");
  };

  if (token && data)
    return (
      <Components.Wrapper>
        <Components.SideCol left>{Help()}</Components.SideCol>
        <Components.MainCol>
          <Components.Row m="0 0 52px" a="space-between">
            {Help()}
            <Components.PageLogo description="Please complete the information below in order to customize your platform" /> {User(email)}
          </Components.Row>
          <AccessInfo onChange={onDataChange} data={data || defaultState} fillError={fillError} />
          <MainInfo onChange={onDataChange} data={data || defaultState} fillError={fillError} />
          <PlatformDesign onChange={onDataChange} onPreview={onChangePreview} data={data || defaultState} />
          <Privacy onChange={onDataChange} data={data || defaultState} />
          <Components.Card>
            <Components.Row m="-5px 0 -10px">
              <Components.Btn width={171} hide>
                Cancel
              </Components.Btn>
              <Components.Btn onClick={onSubmit} width={291}>
                SAVE & Continue TO Terms & Conditions
              </Components.Btn>
            </Components.Row>
          </Components.Card>
        </Components.MainCol>
        <Components.SideCol>{User(email)}</Components.SideCol>
        {preview ? <Preview data={data || defaultState} onClose={onChangePreview} /> : null}
        {wait ? <CreatePlatformLoader /> : null}
        {error && <ErrorMessagePortal er={error} onClose={() => setError(null)} />}
      </Components.Wrapper>
    );
  else if (error) return <ErrorMessagePortal er={error} globalError />;
  else return <Loader />;
};

export default App;
