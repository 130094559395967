import React, { useState, useRef, useEffect, useLayoutEffect, useImperativeHandle, forwardRef, Fragment } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import { InputHidden, CropContainer, Card, Row, Col, ActionButton, FileName } from "./styled";
import { defaultPosition } from "./const";
import { Btn } from "components";
import { UploadIcon } from "components/icons";
import { ErrorMessagePortal } from "components/modal";

export const CropperImg = forwardRef(({ defaultImageDate, onMouseLeave = console.log }, ref) => {
  const [imageData, setImageData] = useState(defaultImageDate || null);
  const [name, setName] = useState(defaultImageDate ? "PlatformLogo.png" : "");
  const [error, setError] = useState(null);

  const inputRef = useRef(null);
  const cropperRef = useRef(null);
  const isMovedTo0x0 = useRef(false);

  const [cropper, setCropper] = useState(null);

  const getCropData = () => {
    try {
      const data = cropperRef.current.getCroppedCanvas().toDataURL();
      return data;
    } catch (err) {}
  };

  useImperativeHandle(ref, () => ({
    getCropData: () => getCropData(),
  }));

  useEffect(() => {
    if (!imageData && inputRef.current) {
      inputRef.current.value = "";
    }
  }, [inputRef, imageData]);

  useLayoutEffect(() => {
    cropperRef.current = cropper;
  }, [cropper]);

  useLayoutEffect(() => {
    if (cropperRef.current) {
      setTimeout(() => {
        cropperRef.current.setCropBoxData(defaultPosition);

        if (!isMovedTo0x0.current) {
          cropperRef.current.moveTo(0, 0);
          isMovedTo0x0.current = true;
        }
      }, 200);
    }
  });

  const onDelegate = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const onRemove = () => {
    setImageData(null);
    setCropper(null);
    setName("");
  };

  return (
    <Card onMouseLeave={onMouseLeave}>
      <InputHidden
        accept="image/*"
        style={{ display: "none" }}
        ref={inputRef}
        onClick={(e) => e.stopPropagation()}
        onChange={async (e) => {
          let img = null;
          if (e.target.files.length) {
            const file = e.target.files[0];
            if (file && file.size > 512000) {
              setError({ error: "Information", message: "The size of the logo should not exceed 0.5Mb" });
              return;
            }
            setName(file.name);
            const reader = new FileReader();
            img = await new Promise((resolve) => {
              reader.onload = () => {
                resolve(reader.result);
              };
              reader.readAsDataURL(file);
            });
          }
          setImageData(img);
        }}
      />
      <Row>
        {imageData ? (
          <Fragment>
            <CropContainer>
              <Cropper
                style={{ height: 50, width: 220 }}
                src={imageData}
                dragMode="move"
                minCropBoxHeight={50}
                cropBoxMovable={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                responsive={false}
              />
            </CropContainer>
            <Col>
              <FileName name={name}>{name || "No Logo Yet"}</FileName>
              <Row h="16px" j="flex-start">
                <ActionButton onClick={onDelegate}>{name ? "Change" : "Upload"} Image</ActionButton>
                {name ? (
                  <ActionButton remove onClick={onRemove}>
                    Delete
                  </ActionButton>
                ) : null}
              </Row>
            </Col>
          </Fragment>
        ) : (
          <Btn fullWidth margin="auto" onClick={onDelegate}>
            <UploadIcon /> Upload Logo
          </Btn>
        )}
      </Row>
      {error && <ErrorMessagePortal er={error} onClose={() => setError(null)} />}
    </Card>
  );
});
