import styled from "styled-components";

export const DocInput = styled.input`
  background: ${({ errorWithBG }) => (errorWithBG ? "rgba(255, 67, 67, 0.05)" : "transparent")};
  height: ${({ height }) => (height ? `${height}px` : "42px")};
  width: ${({ width }) => (width ? width : "calc(100% - 300px)")};
  border: 1px solid ${({ error, edit }) => (error ? "#FF4343" : edit ? "#6280DA" : "#efefef")};
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  padding: ${({ padding }) => padding || "0 20px"};
  margin: ${({ margin = 0 }) => margin};
  font-weight: normal;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "12px")};
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  position: relative;
  &[type="date"]::-webkit-inner-spin-button,
  &[type="date"]::-webkit-calendar-picker-indicator,
  &[type="date"]::-webkit-clear-button,
  &[type="time"]::-webkit-clear-button {
    opacity: 0;
    cursor: pointer;
    -webkit-appearance: none !important;
  }
  &[type="time"]::-webkit-inner-spin-button,
  &[type="time"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    cursor: pointer;
    -webkit-appearance: none;
  }
  &[type="number"]::-webkit-outer-spin-button,
  &[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  background-image: ${({ icon }) => `url(${icon})`};
  background-position: ${({ bgPos = "right" }) => bgPos};
  background-position-x: ${({ arrowPosition, leftPos }) => {
    if (leftPos) return leftPos;
    return arrowPosition ? `calc(100% - ${arrowPosition}px)` : "calc(100% - 24px)";
  }};

  background-size: 16px;
  background-repeat: no-repeat;
  &::placeholder {
    color: rgba(24, 24, 24, 0.3);
  }
  &:disabled {
    background: #faf9f9;
  }
`;

export const InputLabel = styled.div`
  white-space: nowrap;
  font-weight: bold;
  font-size: ${({ labelFsize }) => (labelFsize ? `${labelFsize}px` : "10px")};
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${({ isInvestor }) => (isInvestor ? "rgba(24, 24, 24, 0.6)" : "rgba(24, 24, 24, 0.4)")};
  margin-bottom: ${({ isInvestor }) => (isInvestor ? "8px" : "12px")};
  display: block;
  visibility: ${({ hidden }) => (hidden ? "hidden" : "visible")};
`;

export const ContainerFlex = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${({ row = false }) => (row ? "row" : "column")};
  justify-content: ${({ justify = "flex-start" }) => justify};
  align-items: ${({ align = "flex-start" }) => align};
  ${({ wrap = false }) => (wrap ? "flex-wrap: wrap;" : "")}

  width: ${({ w = "100%" }) => w};
  ${({ h = null }) => (h ? `height: ${h};` : "")}

  padding: ${({ paddingSize = 0 }) => paddingSize};
  margin: ${({ marginSize = 0 }) => marginSize};
  background: ${({ bg = "transparent" }) => bg};
  border-radius: ${({ borderRadius = 0 }) => borderRadius};

  ${({ border = null }) => (border ? `border: ${border};` : "")}
  overflow: ${({ overflow = "hidden" }) => overflow};

  z-index: ${({ zIndex = 0 }) => zIndex};
  transition: height 0.3s linear;
  box-sizing: border-box;
  &::before {
    display: ${({ content }) => (content ? "block" : "none")};
    content: ${({ content }) => (content ? `'${content}'` : "")};
    width: 10px;
    height: 10px;
    bottom: 22px;
    left: 8px;
    color: black;
    position: absolute;
    z-index: 100;
  }
`;

export const DocTextArea = styled.textarea`
  min-height: ${({ areaHeight }) => (areaHeight ? `${areaHeight}px` : "106px")};
  height: ${({ areaHeight }) => `${areaHeight}px`};
  width: ${({ width }) => (width ? width : "100%")};
  border: 1px solid ${({ error }) => (error ? "#ff4343" : "#efefef")};
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  padding: 11px 20px;

  font-weight: normal;
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "14px")};
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  resize: none;
  &::placeholder {
    color: rgba(24, 24, 24, 0.3);
  }
`;

export const TypeContainer = styled.div`
  border: 1px solid #6280da;
  box-sizing: border-box;
  border-radius: 4px;
  transition: 0.15s all linear;
  width: 100%;
  height: 42px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  background: ${({ active }) => (active ? "#6280DA" : "#fff")};
  cursor: pointer;
`;

export const Circle = styled.div`
  width: 18px;
  height: 18px;
  border: 1px solid ${({ active }) => (!active ? "#6280DA" : "#fff")};
  border-radius: 50%;
  position: relative;
  margin-right: 15px;
  &::after {
    content: "";
    top: 3px;
    left: 3px;
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #ffffff;
  }
`;

export const Type = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${({ active }) => (!active ? "#6280DA" : "#fff")};
`;
